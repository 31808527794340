import styled from '@emotion/styled';
import {
  Box,
  StyledButton,
  mediaQueries,
  theme as themev2,
} from '@smartproxy-web/ui';
import { MEDIA_QUERIES } from 'globalStyles';
import { StyledButtonInner } from '../LinkButton/styledComponents';
import { GatsbyLink } from '../GatsbyLink';

interface StyleProps {
  isDark: boolean;
  hasLink?: boolean;
}

interface StyleToggleProps {
  tabsNumber?: number;
  isActive?: boolean;
  color: string;
  showBorder?: boolean;
}

export const ItemsContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  width: '100%',
  margin: '0 auto',
  gap: '24px',
  justifyContent: 'center',
  [mediaQueries.sm]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [mediaQueries.md]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
});

export const StyledCard = styled(GatsbyLink)<StyleProps>(
  ({ hasLink, isDark }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: isDark
      ? themev2.colors.neutral.N94
      : themev2.colors.neutral.N00,
    alignItems: 'start',
    boxShadow: '0px 4px 16px 0px rgba(29, 29, 31, 0.08)',
    padding: '24px',
    borderRadius: '12px',
    border: isDark
      ? `1px solid ${themev2.colors.neutral.N80}`
      : `1px solid ${themev2.colors.white.default}`,
    width: 'auto',
    textAlign: 'left',
    p: { margin: 0 },
    a: {
      color: themev2.colors.neutral.N99,
    },
    position: 'relative',

    [String(StyledDescription)]: {
      color: isDark ? themev2.colors.neutral.N40 : themev2.colors.neutral.N50,
    },

    [String(StyledTitle)]: {
      color: isDark ? themev2.colors.neutral.N00 : themev2.colors.neutral.N99,
    },

    ...(isDark &&
      hasLink && {
        ':hover': {
          cursor: hasLink ? 'pointer' : 'default',
          ':after': {
            borderRadius: '12px',
            position: 'absolute',
            content: '""',
            inset: -1,
            background: themev2.colors.gradient.default,
            padding: 1,
            WebkitMask:
              'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            WebkitMaskComposite: 'xor',
            maskComposite: 'exclude',
          },
        },
      }),

    ...(!isDark && {
      ':hover': {
        border: hasLink
          ? `1px solid ${themev2.colors.primary.P99}`
          : `1px solid ${themev2.colors.white.default}`,
        cursor: hasLink ? 'pointer' : 'default',
      },
    }),
  })
);

export const StyledTitle = styled.div({
  margin: '16px 0 12px',
  'p, em, strong': {
    fontSize: themev2.fonts.size.p_xlarge,
    lineHeight: themev2.fonts.lineHeight.p_medium,
    fontWeight: 700,
  },
});

export const StyledDescription = styled.div({
  fontSize: themev2.fonts.size.p_medium,
  fontFamily: themev2.fonts.family.additional,
  zIndex: 2,
  [String(StyledButton)]: {
    padding: 0,
    height: 'auto',
    span: {
      fontFamily: themev2.fonts.family.additional,
      fontWeight: 400,
      fontSize: themev2.fonts.size.p_medium,
    },
    [String(StyledButtonInner)]: {
      gap: '8px',
    },
  },
});

export const BottomTextContainer = styled.div({
  margin: '0 auto',
  marginTop: '36px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  width: '100%',
  img: {
    width: 'auto',
    height: '24px',
    marginRight: '12px',
    marginBottom: '12px',
  },
  [mediaQueries.sm]: {
    img: {
      marginBottom: 0,
    },
  },
  [mediaQueries.md]: {
    alignItems: 'start',
    flexDirection: 'row',
  },
});

export const StyledBottomText = styled.div<StyleProps>(({ isDark }) => ({
  'p, strong, em, a': {
    display: 'inline',
    fontSize: themev2.fonts.size.p_medium,
    color: isDark ? themev2.colors.neutral.N20 : themev2.colors.neutral.N80,
    a: {
      fontSize: themev2.fonts.size.p_medium,
      color: themev2.colors.blue.default,
      fontFamily: themev2.fonts.family.additional,
    },
  },
}));

export const AwardsImage = styled.div({
  height: 'auto',
  width: '60px',
  div: {
    height: 'auto',
    width: '60px',
  },
});

export const ImageContainer = styled.div({
  width: '128px',
  height: '24px',
  div: {
    width: '128px',
    height: '24px',
  },
});

export const SocialProofToggleButtonContainer = styled.div<StyleToggleProps>(
  ({ color, showBorder }) => ({
    position: 'relative',
    width: 'fit-content',
    borderRadius: '24px',
    padding: '2px',
    border: `1px solid ${themev2.colors.neutral.N10}`,
    display: 'flex',
    backgroundColor: 'transparent',
    ':hover': {
      ...(showBorder
        ? {
            transition: 'all 0.1s ease-in-out 0s',
            background: themev2.colors.neutral.N04,
          }
        : {}),
    },
    [mediaQueries.md]: {
      margin: 'none',
    },

    ...(color === 'gradiant' && {
      backgroundColor: themev2.colors.neutral.N99,
      color: themev2.colors.neutral.N00,
      border: showBorder ? `1px solid ${themev2.colors.neutral.N90}` : 'none',

      ':hover': {
        ...(showBorder && {
          background: themev2.colors.neutral.N94,
        }),
      },
    }),

    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      flexDirection: 'column',
      border: 'none',
      gap: '24px',
      margin: '0 auto',
      width: '100%',
    },
  })
);

export const SocialProofTab = styled.div<StyleToggleProps>(
  ({ isActive, color, showBorder }) => ({
    padding: '12px',
    minWidth: '100px',
    borderRadius: '24px',
    cursor: 'pointer',
    textAlign: 'center',
    color: isActive ? themev2.colors.neutral.N00 : themev2.colors.neutral.N99,
    backgroundColor:
      isActive && showBorder ? themev2.colors.neutral.N99 : 'none',

    border: showBorder ? `1px solid ${themev2.colors.neutral.N10}` : 'none',

    width: '100%',
    [mediaQueries.sm]: {
      width: 'fit-content',
    },

    ':hover': {
      ...(showBorder && !isActive
        ? {
            transition: 'all 0.1s ease-in-out 0s',
            background: themev2.colors.neutral.N04,
          }
        : {}),
    },

    ...(color === 'gradiant' && {
      color: themev2.colors.neutral.N00,

      ...(showBorder &&
        isActive && {
          backgroundColor: themev2.colors.neutral.N99,
          border: '2px double transparent',
          backgroundImage: `linear-gradient(${themev2.colors.neutral.N99}, ${themev2.colors.neutral.N99}), ${themev2.colors.gradient.default}`,
          backgroundOrigin: 'border-box',
          backgroundClip: 'padding-box, border-box',
        }),
      ...(showBorder &&
        !isActive && {
          border: `1px solid ${themev2.colors.neutral.N80}`,
        }),

      ':hover': {
        ...(showBorder &&
          !isActive && {
            background: 'inherit',
          }),
      },
    }),

    fontFamily: themev2.fonts.family.main,
    fontSize: themev2.fonts.size.p_medium,
    lineHeight: 1,
    fontWeight: 500,
    zIndex: 2,
    transition: '0.3s',
  })
);

export const SocialProofSelectedTabBackground = styled(Box)<StyleToggleProps>(
  ({ tabsNumber, color }) => ({
    position: 'absolute',
    top: '2px',
    padding: '12px 24px',
    borderRadius: '24px',
    height: 'calc(100% - 4px)',
    backgroundColor: themev2.colors.neutral.N99,
    zIndex: 1,
    transition: '0.3s',
    minWidth: '100px',

    ...(color === 'gradiant' && {
      backgroundColor: themev2.colors.neutral.N99,
      border: '2px double transparent',
      backgroundImage: `linear-gradient(${themev2.colors.neutral.N99}, ${themev2.colors.neutral.N99}), ${themev2.colors.gradient.default}`,
      backgroundOrigin: 'border-box',
      backgroundClip: 'padding-box, border-box',
    }),
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      minWidth: '60px',
      padding: '12px 12px',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      top: '4px',
      left: '4px',
      height: `calc(100% / ${tabsNumber} - 22px)`,
      width: 'calc(100% - 20px)',
      transition: 'none',
      minWidth: 'unset',
    },
  })
);

export const AuthorImage = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '36px',
  height: '36px',
  minWidth: '36px',
  borderRadius: '50%',
  background: themev2.colors.neutral.N50,
  color: themev2.colors.neutral.N00,
  marginRight: '8px',
});

export const AuthorText = styled.p({
  fontSize: '14px',
  paddingRight: '16px',
  fontFamily: themev2.fonts.family.additional,
  color: themev2.colors.neutral.N50,
});

export const StyledReadMoreContainer = styled.span({
  color: themev2.colors.blue.default,
  fontSize: themev2.fonts.size.p_medium,
  fontFamily: themev2.fonts.family.additional,
  svg: {
    width: 18,
    height: 18,
  },
});
